'use client';

import { type ProductFinderClientProps } from ".";

import { useState } from "react";
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import classNames from "classnames";
import Form from "@components/atoms/form";
import Button from "@components/atoms/button";
import Card from "@components/molecules/card";
import Image from "next/image"
import { object, number } from 'yup';
import useQueryParams from "@/hooks/useQueryParams";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "@components/atoms/form-control";

const IMAGES: { [key: string]: string } = {
  carton: '/product-finder--kartons-und-schachteln.svg',
  bag: '/product-finder--kordelzugbeutel.svg',
  pouch: '/product-finder--versandtasche.svg',
};

const ProductFinderClient = ({
  isSimple = false,
  labels,
  routes,
  errors,
  finderType: propsFinderType
}: ProductFinderClientProps) => {
  const { queryParams, setPathName, removeQueryParamValue } = useQueryParams()

  const [activeFinder, setActiveFinder] = useState(propsFinderType);

  const [length, setLength] = useState<string>(queryParams.get('l') ?? '')
  const [height, setHeight] = useState<string>(queryParams.get('h') ?? '')
  const [width, setWidth] = useState<string>(queryParams.get('w') ?? '')
  const [edited, setEdited] = useState<{ [key: string]: boolean }>({ l: false, h: false, w: false })

  const onSubmit = ({ length, width, height }: any) => {
    setPathName(routes[activeFinder], [
      ...(length && [{ name: 'l', value: length }]),
      ...(width && [{ name: 'w', value: width }]),
      ...(height && [{ name: 'h', value: height }])
    ])
  }

  const handleClear = (key: string) => {
    if (true === edited[key]) {
      switch (key) {
        case 'l':
          setLength(queryParams.get('l') ?? '')
          setEdited((edited) => Object.assign(edited, { l: false }))
          return
        case 'w':
          setWidth(queryParams.get('w') ?? '')
          setEdited((edited) => Object.assign(edited, { w: false }))
          return
        case 'h':
          setHeight(queryParams.get('h') ?? '')
          setEdited((edited) => Object.assign(edited, { h: false }))
          return
        default:
          return
      }
    } else {
      removeQueryParamValue(key, queryParams.get(key) ?? '')
    }
  }

  // @TODO: carton-finder-main -> pb-2
  return (
    <div className={classNames('carton-finder-main  d-flex', [
      { 'mx-3': true === isSimple },
      { 'h-100 w-100': false === isSimple },
      { [`product-finder product-finder__${propsFinderType}`]: propsFinderType && isSimple }
    ])}>
      <Form
        initialValues={{ width, height, length }}
        showErrors={false}
        validationSchema={object().shape({
          length: number().required(errors.required).positive().integer(),
          width: number().required(errors.required).positive().integer(),
          height: number().positive(errors.required).integer().when([], {
            is: () => 'carton' === activeFinder,
            then: () => number().required(errors.required)
          }),
        })}
        onSubmit={onSubmit}
        className={'w-100'}
      >
        {true === isSimple ? (
          <div className={'d-flex  flex-column  justify-content-center  mt-2'}>
            <span className={'H4-Headline-Default'}>{labels.headline}</span>
            <div className={'d-flex'}>
              <div className={'m-0 me-2 product-finder-search-fields-field__max-width'}>
                <label className={'fw-bold  form-label'} htmlFor={'length'}>{labels.length}</label>
                <div className={'border input-group'}>
                  <FormControl id={'length'} className={'border-0'} value={length} onChange={(e: any) => {
                    setLength(e.currentTarget.value)
                    setEdited((edited) => Object.assign(edited, { l: true }))
                  }} placeholder={'Länge'} />
                  {(true === edited['l']) && (
                    <span className={classNames('d-inline-flex  justify-content  align-items-center  bg-white  px-1  icon  icon--x  icon--size__xs  opacity-50')} onClick={() => handleClear('l')} />
                  )}
                </div>
              </div>
              <div className={'m-0 me-2 product-finder-search-fields-field__max-width'}>
                <label className={'fw-bold  form-label'} htmlFor={'width'}>{labels.width}</label>
                <div className={'border input-group'}>
                  <FormControl id={'width'} className={'border-0'} value={width} onChange={(e: any) => {
                    setWidth(e.currentTarget.value)
                    setEdited((edited) => Object.assign(edited, { w: true }))
                  }} placeholder={'Breite'} />
                  {(true === edited['w']) && (
                    <span className={classNames('d-inline-flex  justify-content  align-items-center  bg-white  px-1  icon  icon--x  icon--size__xs  opacity-50')} onClick={() => handleClear('w')} />
                  )}
                </div>
              </div>
              {'carton' === activeFinder && (
                <div className={'m-0 me-2 product-finder-search-fields-field__max-width'}>
                  <label className={'fw-bold  form-label'} htmlFor={'height'}>{labels.height}</label>
                  <div className={'border  input-group'}>
                    <FormControl id={'height'} className={'border-0'} value={height} onChange={(e: any) => {
                      setHeight(e.currentTarget.value)
                      setEdited((edited) => Object.assign(edited, { h: true }))
                    }} placeholder={'Höhe'} />
                    {(true === edited['h']) && (
                      <span className={classNames('d-inline-flex  justify-content  align-items-center  bg-white  px-1  icon  icon--x  icon--size__xs  opacity-50')} onClick={() => handleClear('h')} />
                    )}
                  </div>
                </div>
              )}
              {isSimple && (
                <div className="search-btn-col  d-flex  align-items-end mt-2">
                  <Button type={'submit'} icon={'search'} iconWidth={'sm'} iconVariant={'white'} variant={'secondary'}>
                    {labels.button}
                  </Button>
                </div>
              )}
            </div>
            <span className={'fs-9  mt-2'}>
              {labels.subline?.[activeFinder]}
            </span>
          </div>
        ) : (
          <Card className={'carton-finder-card  d-flex  flex-column  justify-content-between  px-0  pb-0'} loading={false} header={'Produktfinder'}>
            <div className={'px-3'}>
              <Navbar className={'mx-n3  mt-n3  py-0  fs-6'}>
                <Nav.Link
                  className={'d-flex  gap-1  icon  icon--box  align-items-center  nav-icon'}
                  active={activeFinder == 'carton'}
                  onClick={() => setActiveFinder('carton')}
                >
                  {labels.carton}
                </Nav.Link>
                <Nav.Link
                  className={'d-flex  gap-1  icon  icon--bag  align-items-center  nav-icon'}
                  active={activeFinder == 'pouch'}
                  onClick={() => setActiveFinder('pouch')}
                >
                  <span>{labels.pouch}</span>
                </Nav.Link>
                <Nav.Link className={'d-flex  gap-1  icon  icon--envelope  align-items-center  nav-icon'} active={activeFinder == 'bag'} onClick={() => setActiveFinder('bag')}>
                  {labels.bag}
                </Nav.Link>
              </Navbar>
              <div className={'d-none  d-md-flex  carton-finder-background  position-relative  justify-content-center  mx-n3  flex-fill'}>
                <Image key={'productfinder_image_' + activeFinder} className={'d-inline-block  object-fit-contain  h-100'} alt={''} src={IMAGES[activeFinder]} fill />
              </div>
            </div>

            <div className={'px-3  my-3'}>
              <Row>
                <Col className={'px-2'}>
                  <label className={'fw-bold  form-label'} htmlFor={'length'}>{labels.length}</label>
                  <FormControl id={'length'} className={'border input-group'} value={length} onChange={(e: any) => {
                    setLength(e.currentTarget.value)
                    setEdited((edited) => Object.assign(edited, { l: true }))
                  }} placeholder={'Länge'} />
                </Col>
                <Col className={'px-2'}>
                  <label className={'fw-bold  form-label'} htmlFor={'width'}>{labels.width}</label>
                  <FormControl id={'width'} className={'border input-group'} value={width} onChange={(e: any) => {
                    setWidth(e.currentTarget.value)
                    setEdited((edited) => Object.assign(edited, { w: true }))
                  }} placeholder={'Breite'} />
                </Col>
                {'carton' === activeFinder && (
                  <Col className={'px-2'}>
                    <label className={'fw-bold  form-label'} htmlFor={'height'}>{labels.height}</label>
                    <FormControl id={'height'} className={'border input-group'} value={height} onChange={(e: any) => {
                      setHeight(e.currentTarget.value)
                      setEdited((edited) => Object.assign(edited, { h: true }))
                    }} placeholder={'Höhe'} />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className={'fs-9 mt-2  px-2'}>
                  {labels.subline?.[activeFinder]}
                </Col>
              </Row>
            </div>

            <Button type={'submit'} icon={'search'} iconWidth={'sm'} iconVariant={'white'} className={'justify-content-center  py-3'} variant={'secondary'}>
              {labels.button}
            </Button>
          </Card>
        )}
      </Form>
    </div>
  )
};

export default ProductFinderClient;
